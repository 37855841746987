import "./src/styles/global.css";
/**
 * Load the required analytics libraries
 */
import "@jmc/analytics-transGTM";

import anchorScroll from "@jmc/core/src/utils/anchor-scroll";
import { createLocale } from "@jmc/utils/utils/createLocale";
import loadable from "@loadable/component";
import { create } from "@redux";
import { load as authValid } from "@redux/modules/authValid";
import { clear as clearMaterialCollection } from "@redux/modules/materialCollection";
import React, { useEffect } from "react";
import { initMessageListener } from "redux-state-sync";
import yn from "yn";

import { rootElement } from "./gatsby-shared";
import { Layout } from "./src/components/Layout/Layout";

const NotSupported = loadable(() => import("./src/pages/NotSupported/NotSupported"));

if (process.env.GATSBY_ENVIRONMENT !== "prod") {
    // this is a debugging/testing library and not loaded on prod
    // required for: https://chrome.google.com/webstore/detail/event-based-web-analytics/geldghkdegbchcgbifnenjbddkfonpgb
    require("@jmc/analytics-transQA");
    require("@jmc/utils/utils/analytics-e2e");
}

// Create the redux store.
const store = create(true);
initMessageListener(store);

export const wrapRootElement = (element) => rootElement(element, store);

// Internet Explorer doesn't support `ChildNode.remove()` at all
// Add a polyfill for support it.
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function (arr) {
    arr.forEach(function (item) {
        if (item.hasOwnProperty("remove")) {
            return;
        }
        Object.defineProperty(item, "remove", {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                this.parentNode.removeChild(this);
            },
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

// onClientEntry is called when the Gatsby browser runtime first starts.
// We check whether the user is logged in or not.
export const onClientEntry = () => {
    const allLanguages = process.env.GATSBY_LOCALES?.split(",");
    const locale = createLocale(location.pathname, 1, allLanguages);
    store.dispatch(authValid({ language: locale })).then(({ type }) => {
        if (type.endsWith("/failure")) {
            store.dispatch(clearMaterialCollection());
        }
    });
};

/**
 * @usage Used by QA for the end-to-end tests
 *
 * This will set window.appStatus to true when all
 * components that are children of this component are mounted
 *
 * @remark
 *
 * This does not mean that all components are in their final state (fi. changes after api calls)
 * just that all the child elements have there componentDidMount called and are rendered.
 *
 * @param {{ children: any }} param0 the children to check if they rendered
 */
const CheckComponentsMounted = ({ children }) => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.appStatus = true;
        }
    });

    return children;
};

/**
 * Check if browser is not supported (Internet Explorer) to display unsupported page.
 */
const useragent = navigator.userAgent;
const hasUnsupportedBrowser = useragent.indexOf("MSIE ") > -1 || useragent.indexOf("Trident/") > -1;

/**
 * The page wrapPageElement will return a Layout component that is connected with Redux and sets the inital pageContext.
 * Normally the pageContext will be set by Gatsby, but using a Redux module for it allows us to update the context on runtime.
 * For example the commercial_content flag is determined by a runtime backend call for events instead of by the CMS.
 */
export const wrapPageElement = ({ element, props }) => {
    return (
        <CheckComponentsMounted>
            <Layout {...props} hasUnsupportedBrowser={hasUnsupportedBrowser}>
                {!hasUnsupportedBrowser ? element : <NotSupported />}
            </Layout>
        </CheckComponentsMounted>
    );
};

/*
 * Set the app status to false when the route is changing
 * (Used for test automation)
 */
export const onPreRouteUpdate = () => {
    if (typeof window !== "undefined") {
        window.appStatus = false;
    }
};

// Checks if new route has anchor and scrolls to it with navigation offset.
export const onRouteUpdate = ({ location, prevLocation }) => {
    // Way of detecting direct page loads for disclaimers:
    // since document.referrer is unreliable in SPAs, keep track of previousPath on window object
    window.locations = window.locations || [document.referrer];
    let length = window.locations.length;
    if (window.locations[length - 1] !== window.location.href) {
        window.locations.push(window.location.href);
    }
    length = window.locations.length;
    // in rare cases, pages reload themselves and then the document.referrer is the same as the current href, so we end up with an array of 1
    window.previousPath = length >= 2 ? window.locations[length - 2] : "";

    if (yn(process.env.GATSBY_THIRD_PARTY_REDIRECTION_ENABLED) && location?.search) {
        const params = new URLSearchParams(location.search);
        const anchorId = params.get("a");
        if (anchorId) {
            params.delete("a");
            let paramsStr = params.toString();
            paramsStr = paramsStr ? `?${paramsStr}` : "";
            window.location.replace(`${location.pathname}${paramsStr}#${anchorId}`);
        }
    }
    if (location?.hash) {
        const listenerCallback = () => {
            const anchor = location.hash?.substring(1);
            setTimeout(() => {
                document?.fonts?.ready?.then(() => {
                    anchor && anchorScroll(decodeURIComponent(anchor));
                });
            }, 750);
            window.removeEventListener("bodyLoaded", listenerCallback, { once: true });
        };
        window.addEventListener("bodyLoaded", listenerCallback, { once: true });

        // Checks if all the Suspense components are loaded to dispatch the bodyLoaded event and scroll to the anchor
        const intervalId = setInterval(() => {
            const suspenses = document?.querySelectorAll('[id^="suspense-div"]');
            if (suspenses?.length === 0) {
                listenerCallback();
                clearInterval(intervalId);
            }
        }, 500);
        // Stops checking if all the Suspense components are loaded after n seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 20000);
    } else {
        //added settimeout to prevent scrolltotop issue in firefox browser
        setTimeout(() => {
            if (!hasUnsupportedBrowser) document?.body?.scroll({ top: 0, left: 0, behavior: "smooth" });
        }, 50);
    }

    /*
     * Added below code to load the OneTrust script to reload on cookie policy page
     * (as a SPA application concept from OneTrust)
     * there is timeout set to wait for the script execution till complete page load.
     */
    setTimeout(function () {
        // Check the page has cookie list ID from OneTrust to reload the script.
        const otCookieList = document.getElementById("ot-sdk-cookie-policy");
        if (otCookieList) {
            // before reloading the script, remove the existing the OneTrust loaded DIV to avoid duplicates
            const otConsentSdk = document.getElementById("onetrust-consent-sdk");
            if (otConsentSdk) {
                otConsentSdk.remove();
            }
            // Reload the Script by calling onetrust.loadbanner() function.
            window?.OneTrust?.LoadBanner();
        }
    }, 500);
};
