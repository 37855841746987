import React from "react";
import classnames from "classnames";
import style from "./style.module.scss";
import { default as MdiIcon } from "@mdi/react";

export type IconColorsType =
    | "primary"
    | "secondary"
    | "white"
    | "black"
    | "success"
    | "error"
    | "inherit"
    | "primary-light"
    | "text-light"
    | "text-dark"
    | "text-grey"
    | "text-grey-300"
    | "grey-light"
    | "green-light"
    | "disabled";

interface PropTypes {
    icon: string;
    size?: string | "xxs" | "xs" | "small" | "medium" | "large" | "xl" | "xxl";
    color?: IconColorsType;
    verticalAlignMiddle?: boolean; // allows to tweak the vertical alignment of icons; some require it while others don't
    className?: string;
    type?: "mdi" | "jnj";
}

export const jnjHome =
    "M11 29h6v-9.99h-6V29zm15.97-15.66c-.85-1.6-.15-3.8-2.22-5.13-2.03-1.3-3.87-.32-5.12.4L14 3 2 14.96V29h2V15.81l10-9.98 10 10V29h3.84c-.3-.73-.62-1.53-.83-2.16-.79-2.42-.61-3.25.1-4.35 1.21-1.86 2.98-2.4 2.88-4.46-.12-2.68-2.28-3.29-3.02-4.69z";
export const jnjLock =
    "M22.83,13h-0.09V8.73C22.73,5.02,19.71,2,16,2S9.27,5.02,9.27,8.73V13H9.17H6.5v7.82C6.63,25.97,10.75,30,16,30 c5.25,0,9.5-4.03,9.5-9.18V13H22.83z M12.27,8.73C12.27,6.68,13.94,5,16,5c2.06,0,3.73,1.67,3.73,3.73V13h-7.47V8.73z M23.5,20.82 c0,0.7-0.1,1.37-0.28,2c0,0.01,0,0.01-0.01,0.01C22.17,25.54,19.09,27,16,27s-6.17-1.46-7.22-4.17c0-0.01,0-0.01,0-0.01 c-0.18-0.63-0.28-1.31-0.28-2V15h15V20.82z M15,21.47v2.83h2v-2.83c0.8-0.38,1.35-1.18,1.35-2.12c0-1.3-1.05-2.35-2.35-2.35 s-2.35,1.05-2.35,2.35C13.65,20.29,14.2,21.09,15,21.47z";
export const jnjArrowLeft =
    "M2 15.9646L16.6666 2L18.6666 3.77215L9.03696 14.1924H30V17.7367H9.03696L18.5925 28.1569L16.6666 30L2 15.9646Z";
export const jnjArrowRight =
    "M30 15.9646L15.3334 2L13.3334 3.77215L22.963 14.1924H2V17.7367H22.963L13.4075 28.1569L15.3334 30L30 15.9646Z";
export const jnjChevronDown =
    "M16.3689 24.3333L30.3335 9.66668L28.5613 7.66669L16.3335 18.625L4.1766 7.74083L2.3335 9.66668L16.3689 24.3333Z";
export const jnjChevronUp =
    "M16.2981 7.66668L2.3335 22.3333L4.10565 24.3333L16.3335 13.375L28.4904 24.2592L30.3335 22.3333L16.2981 7.66668Z";
export const jnjChevronLeft =
    "M7.99988 15.9646L22.6665 2L24.6665 3.77215L13.7082 16L24.5924 28.1569L22.6665 30L7.99988 15.9646Z";
export const jnjChevronRight =
    "M24.6666 15.9646L9.99999 2L8 3.77215L18.9583 16L8.07415 28.1569L9.99999 30L24.6666 15.9646Z";
export const jnjPerson =
    "M16,13.02c3.04,0,5.51-2.47,5.51-5.51S19.04,2,16,2c-3.04,0-5.51,2.47-5.51,5.51S12.96,13.02,16,13.02z M16,4c1.93,0,3.51,1.57,3.51,3.51c0,1.93-1.57,3.51-3.51,3.51c-1.93,0-3.51-1.57-3.51-3.51C12.5,5.57,14.07,4,16,4z M25.24,30c0,0-0.19-7.15-3.65-10.61L22.84,30H16H9.15l1.25-10.61C6.95,22.85,6.76,30,6.76,30H2.77c0,0,0.44-2.67,1.35-5.28C6.1,19.06,9.46,14.97,16,14.97s9.9,4.1,11.88,9.76c0.91,2.61,1.36,5.28,1.36,5.28H25.24z";

/**
 * Icon component
 *
 * Can use icons from :
 * - React Mdi library (https://pictogrammers.com/library/mdi/)
 * - JnJ branded icons (https://brandcenter.jnj.com/document/444)
 *
 */
export const Icon = ({
    icon,
    size = "medium",
    color = "primary",
    verticalAlignMiddle = false,
    className,
    type = "mdi",
    ...other
}: PropTypes): JSX.Element => {
    let theSize = size;
    switch (size) {
        case "xxs":
            theSize = "0.5rem";
            break;
        case "xs":
            theSize = "1rem";
            break;
        case "small":
            theSize = "1.25rem";
            break;
        case "medium":
            theSize = "1.5rem";
            break;
        case "large":
            theSize = "1.75rem";
            break;
        case "xl":
            theSize = "3rem";
            break;
        case "xxl":
            theSize = "6rem";
            break;
        case "inherit":
            theSize = "1.25em";
            break;
        default:
            break;
    }

    return (
        <span
            className={classnames(
                style.element,
                verticalAlignMiddle && style.verticalAlignMiddle,
                color && style[color],
                className,
            )}
            {...other}
        >
            {type === "mdi" ? (
                <MdiIcon path={icon} size={theSize} />
            ) : (
                <svg width={theSize} height={theSize} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d={icon} style={{ fill: "currentColor" }} />
                </svg>
            )}
        </span>
    );
};

Icon.displayName = "Icon";
