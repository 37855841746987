import style from "./style.module.scss";

import React, { createContext, ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { mdiCloseCircleOutline, mdiClose } from "@mdi/js";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Paper } from "@jmc/solid-design-system/src/components/atoms/Paper/Paper";
import ReactDOM from "react-dom";
import { RemoveScroll } from "react-remove-scroll";
import { Actions } from "./Actions";
import { Content } from "./Content";
import { Visual } from "./Visual";
import { Title } from "./Title";

interface Props {
    open?: boolean;
    blurBackground?: boolean;
    children: string | ReactNode;
    logo?: ReactNode;
    onClose?: (event: Event) => void;
    position?: "center" | "topRight" | "bottomCenter";
    size?: "medium" | "large" | "full";
    spacing?: "none" | "extra-small" | "small" | "medium" | "large" | "extra-large";
    showClose?: boolean;
    overflow?: "auto" | "visible" | "hidden";
    titleFullWidth?: boolean;
    noMargins?: boolean;
    enableRemoveScroll?: boolean;
    disableHtmlScroll?: boolean;
    noSnippet?: boolean; //Prevent the content of the modal from displaying in a search result snippet
    closeModalTitle?: string;
    simpleCloseIcon?: boolean;
    closeText?: boolean;
}

export const ModalContext = createContext(null);

export const Modal = ({
    children,
    logo = undefined,
    open = true,
    blurBackground = true,
    position = "center",
    size = "medium",
    spacing = "extra-large",
    onClose = undefined,
    overflow = "auto",
    showClose = true,
    titleFullWidth = false,
    noMargins = false,
    enableRemoveScroll = true,
    disableHtmlScroll = false,
    noSnippet = false,
    closeModalTitle = "Close",
    simpleCloseIcon = false,
    closeText = false,
}: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const closeRef = useRef<HTMLDivElement>(null);
    const [portal, setPortal] = useState<HTMLElement | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (typeof window === "undefined") return;

        const portalElement = document.createElement("div");
        document.body.appendChild(portalElement);
        setPortal(portalElement);

        const handleClickOutside = (event: any) => {
            if (
                !wrapperRef.current?.contains(event.target) ||
                (wrapperRef.current?.contains(event.target) && wrapperRef && event.target === wrapperRef.current)
            ) {
                onClose && onClose(event);
            }
            document.body.style.overflow = "";
        };

        portalElement.addEventListener("mouseup", handleClickOutside);
        window.addEventListener("keydown", handleFocus);

        return () => {
            if (portalElement) {
                document.body.removeChild(portalElement);
                portalElement.removeEventListener("mouseup", handleClickOutside);
            }
        };
    }, [onClose]);

    useEffect(() => {
        if (open && disableHtmlScroll && typeof window !== "undefined") {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [open, disableHtmlScroll]);

    const handleClickCloseIcon = (event: any) => {
        onClose && onClose(event);
        document.body.style.overflow = "";
    };

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleClickCloseIcon(event);
        }
    };

    const handleFocus = (event: any) => {
        if (event.key === "Tab" && document.querySelectorAll("[data-test-id='Modal']")[0]) {
            window.removeEventListener("keydown", handleFocus);
            closeRef.current?.focus();
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleFocus);

        return () => {
            window.removeEventListener("keydown", handleFocus);
        };
    }, []);

    const modal = open && (
        <ModalContext.Provider value={{ component: "modal" }}>
            <RemoveScroll enabled={enableRemoveScroll}>
                <div
                    className={classnames(
                        style.element,
                        blurBackground ? style.blurBackground : null,
                        style[`position_${position}`],
                        style[`size_${size}`],
                    )}
                    data-test-id="Modal"
                    onClick={(e) => e.stopPropagation()}
                    {...(noSnippet ? { "data-nosnippet": true } : {})}
                    role="presentation"
                >
                    <div
                        className={classnames(
                            style.wrapper,
                            titleFullWidth ? style.wrapper_titleFullWidth : null,
                            noMargins ? style.wrapper_noMargins : null,
                        )}
                        ref={wrapperRef}
                        id="modalWrapper"
                    >
                        <Paper
                            className={classnames(style.modal_content, style[`modal_content__overflow_${overflow}`])}
                            spacing={spacing}
                            tabIndex={0}
                        >
                            {logo && (
                                <div className={style.logo} data-test-id="modalLogo">
                                    {logo}
                                </div>
                            )}
                            {onClose && showClose && (
                                <div
                                    tabIndex={0}
                                    className={style.closeIcon}
                                    onKeyUp={handleKeyPress}
                                    onClick={handleClickCloseIcon}
                                    data-test-id="Modal.Close"
                                    title={closeModalTitle}
                                    role="button"
                                    ref={closeRef}
                                >
                                    {closeText && (
                                        <span className={style.closeText} data-test-id="closeText">
                                            {t("Close", { ns: "common" })}
                                        </span>
                                    )}
                                    <Icon
                                        icon={simpleCloseIcon ? mdiClose : mdiCloseCircleOutline}
                                        color="primary"
                                        size="large"
                                        verticalAlignMiddle={closeText ? true : false}
                                    />
                                </div>
                            )}
                            <div className={style.contentWrapper}>{children}</div>
                        </Paper>
                    </div>
                </div>
            </RemoveScroll>
        </ModalContext.Provider>
    );

    return portal ? ReactDOM.createPortal(modal, portal) : modal;
};

Modal.Visual = Visual;
Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

export const ModalConsumer = ({ children }: { children: any }) => (
    <ModalContext.Consumer>
        {(context: any) => {
            if (!context) {
                throw new Error("Modal compound components cannot be rendered outside the Modal component");
            }
            return children(context);
        }}
    </ModalContext.Consumer>
);
