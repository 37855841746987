import React, { ReactNode } from "react";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";

import style from "./style.module.scss";
import classnames from "classnames";
import cleanCSSIdentifier from "@jmc/utils/utils/clean-css-identifier";

export enum Size {
    AUTO = "auto",
    "X-Small" = "X-Small",
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
}

export enum Variant {
    CONTENT = "content",
    MEDIA_SMALL = "mediaSmall",
    MEDIA_LARGE = "mediaLarge",
    TEXT = "text", // This one is just mediaLarge without the image
}

export enum Color {
    WHITE = "white",
    PRIMARY = "primary",
    GRAY = "gray",
    DEFAULT = "default",
}

export interface Props {
    id: string;
    title: string;
    titleComponent?: JSX.Element | string;
    content?: JSX.Element | string;
    actions?: JSX.Element | JSX.Element[];
    image?: JSX.Element;
    children?: ReactNode;
    variant?: Variant;
    size?: Size;
    color?: Color;
}

/**
 * Rebranded version of the old Hero component.
 *
 * This type of hero is only used in the page headers.
 * Comes from Hero content type in the CMS
 */
export const HeaderHero = (props: Props): JSX.Element => {
    const {
        id,
        title,
        titleComponent,
        content,
        actions,
        image,
        size = Size.AUTO,
        variant = Variant.CONTENT,
        color = Color.DEFAULT,
        children,
    } = props;

    let finalVariant = variant;
    if (variant === Variant.CONTENT && !image) {
        finalVariant = Variant.MEDIA_LARGE;
    }

    return (
        <div
            className={classnames(
                style.hero,
                style[`variant__${finalVariant || Variant.MEDIA_LARGE}`],
                style[`color__${color}`],
            )}
            data-test-id="Hero"
            id={`Hero.${cleanCSSIdentifier(id?.toLowerCase())}`}
        >
            <div className={classnames(style.body)}>
                <div className={style.title} data-test-id="Hero.Title">
                    <Typography variant="h1" color="inherit" weight="400">
                        {titleComponent || title}
                    </Typography>
                </div>
                <div className={style.content} data-test-id="Hero.Content">
                    {content || children}
                </div>
                <div className={style.actions} data-test-id="Hero.Actions">
                    {actions}
                </div>
            </div>

            {image && (
                <div className={classnames(style.media, style[`size__${size}`])} data-test-id="Hero.Media">
                    {image}
                </div>
            )}
        </div>
    );
};
