import { AuthHeaderItem } from "@components/JMCHeader/AuthHeaderItem/AuthHeaderItem";
import { LangSwitcher } from "@components/JMCHeader/LangSwitcher/LangSwitcher";
import { JMCLink } from "@components/JMCLink/JMCLink";
import JMCMegaMenu from "@components/JMCMegaMenu/JMCMegaMenu";
import { MedicalRibbon } from "@components/MedicalRibbon/MedicalRibbon";
import { NavigationImpression } from "@components/NavigationImpression/NavigationImpression";
import { Header } from "@jmc/core/src/components/Header/Header";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { Button } from "@jmc/solid-design-system/src/components/atoms/Button/Button";
import { Icon, jnjHome } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { Notification } from "@jmc/solid-design-system/src/components/atoms/Notification/Notification";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { Tooltip } from "@jmc/solid-design-system/src/components/molecules/Tooltip/Tooltip";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";
import path from "@jmc/utils/utils/path";
import { mdiCartOutline, mdiTriangle } from "@mdi/js";
import { ApplicationState } from "@redux/modules";
import { ValidAuthResults } from "@redux/modules/authValid";
import {
    CMSEventProps,
    CMSFreeFormPageSiteReference,
    CMSMaterialProps,
    CMSProductProps,
    CMSSpecialtyProps,
    PageContext,
} from "@types";
import { getSearchUrl } from "@utils/getSearchUrl";
import { getLocalisedUrlFunc } from "@utils/navigate";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import yn from "yn";

import { SEARCH_URL } from "../../../plugins/JMCPageCreator/Constants";
import Logo from "./Logo";
import { MaterialOverlay } from "./MaterialOverlay/MaterialOverlay";
import style from "./style.module.scss";
import useShell from "./useShell";

interface PropTypes {
    pageContext: PageContext;
    shouldAddProductTheme: boolean;
    medical: boolean;
}

export const JMCHeader = ({ pageContext, shouldAddProductTheme, medical = false }: PropTypes): JSX.Element => {
    const configuration = useShell();
    const { jnjFullBranded } = useJnjBranding();

    const siteReference = (): CMSFreeFormPageSiteReference => {
        const { site_reference } = pageContext?.page || {};
        return site_reference?.[0];
    };

    const materialCount = useSelector((state: ApplicationState) => {
        const collection = state.materialCollection as Map<string, { material: CMSMaterialProps; amount: number }>;
        let cartTotalItem = 0;
        collection.forEach(function (material) {
            cartTotalItem = cartTotalItem + material.amount;
        });
        return cartTotalItem;
    });

    const [pharmacovigiliance, setPharmacovigiliance] = useState(false);
    const [logo, setLogo] = useState(
        pageContext?.logo
            ? pageContext?.logo // used by tcp
            : shouldAddProductTheme
            ? siteReference()?.branding?.logo || siteReference()?.relation?.[0]?.productLogo || configuration?.logo
            : configuration?.logo,
    );
    const [useFullWidth, setUseFullWidth] = useState(Boolean(pageContext.headerFullWidth));
    const { t } = useTranslation();
    const jmcNavigate = (query: string): void => {
        const site = siteReference()?.miniSiteTitle;
        const searchUrl = getSearchUrl(pageContext?.locale, query, site);
        navigate(searchUrl);
    };

    //limit height of the image to the size of the buttons also present in the Header
    const { allContentstackSearch } = useStaticQuery(graphql`
        {
            allContentstackSearch {
                nodes {
                    publish_details {
                        locale
                    }
                    enable_service
                }
            }
        }
    `);

    const authData = useSelector(
        ({ authValid }: { authValid: { data: ValidAuthResults } }) => authValid?.data,
        isEqual,
    );
    const getLocalisedUrl = getLocalisedUrlFunc(pageContext?.locale);
    const search = allContentstackSearch?.nodes?.find((node) => node?.publish_details?.locale === pageContext?.locale);
    const searchServiceEnabled = search?.enable_service && yn(process.env.GATSBY_SEARCH_ENABLED, { default: false });
    const isTCPPage = pageContext?.pagePath?.includes("/services/tcp");
    const isDedicatedSearchPage = pageContext?.url === SEARCH_URL;
    const urlPage = getLocalisedUrl(siteReference()?.startpage?.[0]?.url || "").replace("undefined", "");
    const isScientificPublicationPage = pageContext?.service === "Scientific Publications";
    const relatedTo =
        siteReference()?.relation?.[0] ?? ({} as CMSProductProps[] | CMSSpecialtyProps[] | CMSEventProps[]);
    const isMobile = useMediaQuery(BreakPoint.sm);

    const isPreviousSearchEnabled = (): boolean => {
        if (!authData) {
            return !yn(process.env.GATSBY_SEARCH_L1_DISABLE_PREVIOUS, { default: false });
        }
        return true;
    };

    const isPopularSearchEnabled = (): boolean => {
        if (!authData) {
            return !yn(process.env.GATSBY_SEARCH_L1_DISABLE_POPULAR, { default: false });
        }
        return true;
    };

    const isAutcompleteSearchEnabled = (): boolean => {
        if (!authData) {
            return !yn(process.env.GATSBY_SEARCH_L1_DISABLE_AUTOCOMPLETE, { default: false });
        }
        return true;
    };

    useEffect(() => {
        if ("pharmacovigiliance" in relatedTo && shouldAddProductTheme) {
            setPharmacovigiliance(relatedTo.pharmacovigiliance);
        } else {
            setPharmacovigiliance(false);
        }
    }, [relatedTo, shouldAddProductTheme]);

    useEffect(() => {
        setLogo(
            pageContext?.logo
                ? pageContext?.logo //used for tcp
                : shouldAddProductTheme
                ? siteReference()?.branding?.logo || siteReference()?.relation?.[0]?.productLogo || configuration?.logo
                : configuration?.logo,
        );
    }, [pageContext, shouldAddProductTheme]);

    useEffect(() => {
        setUseFullWidth(Boolean(pageContext.headerFullWidth));
    }, [pageContext]);

    const { availableLocales = process.env.NODE_ENV !== "production" ? process.env.GATSBY_LOCALES?.split(",") : [] } =
        pageContext;

    const isMinisite = !!siteReference()?.relation;

    const getCartCountString = (count: number): string => {
        if (count === 0) return;
        if (count > 9) return "9+";
        return `${count}`;
    };

    const cartMenuItem = (
        <>
            {materialCount > 0 && (
                <Notification
                    size="small"
                    notifications={getCartCountString(materialCount)}
                    onClick={(): void =>
                        (document.getElementById("dialogMaterialOrder") as HTMLDialogElement).showModal()
                    }
                >
                    <Icon icon={mdiCartOutline} size="small" />
                </Notification>
            )}
        </>
    );

    if (isDedicatedSearchPage && isMobile) {
        return <> {medical && <MedicalRibbon isScientificPublicationPage={isScientificPublicationPage} />}</>;
    }

    const headerWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => {
        return (
            <div
                data-theme={shouldAddProductTheme && pageContext.emea_product}
                data-test-id="Shell.Header"
                data-nosnippet
                className={style.headerWrapper}
            >
                <NavigationImpression
                    name="header_navigation"
                    type={EventTypes.HEADER_IMPRESSION}
                    checkVisibility={false}
                >
                    {children}
                </NavigationImpression>
            </div>
        );
    };

    const skipTabFocusToBody = (): void => {
        const body = document.getElementById("body");
        // Find all focusable elements within the body of the page and focus the first one
        const e: HTMLElement = [
            ...body.querySelectorAll('a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'),
        ].filter((el) => !el.hasAttribute("disabled"))?.[0];
        e.focus();
    };

    return (
        <>
            {medical && <MedicalRibbon isScientificPublicationPage={isScientificPublicationPage} />}
            <MaterialOverlay logo={logo} />
            <div className={style.skip}>
                <Button rounded={false} tabIndex={1} onClick={() => skipTabFocusToBody()}>
                    <Typography font="title" color="white" size="l">
                        {t("skip to content", { ns: "navigation" })}
                    </Typography>
                </Button>
            </div>
            <Header
                topBarContent={
                    siteReference() && siteReference()?.startpage?.[0]?.url != "/" && !medical ? (
                        <JMCLink
                            url={path.join(process.env.GATSBY_APP_DOMAIN, pageContext?.locale)}
                            data-test-id="Header.BackToHome"
                        >
                            {jnjFullBranded && (
                                <Icon
                                    color="white"
                                    icon={jnjHome}
                                    type="jnj"
                                    size="medium"
                                    verticalAlignMiddle={true}
                                />
                            )}

                            <Typography
                                color="secondary"
                                font={jnjFullBranded ? "text" : "title"}
                                component="span"
                                size={"m"}
                            >
                                {t("Back to Janssen Medical Cloud", { ns: "navigation" })}
                            </Typography>
                        </JMCLink>
                    ) : null
                }
                logo={
                    <div style={{ display: "flex" }}>
                        {medical ? (
                            <Logo logo={logo}></Logo>
                        ) : (
                            <Link id={urlPage} to={urlPage}>
                                <Logo logo={logo}></Logo>
                            </Link>
                        )}
                        {pharmacovigiliance && (
                            <div className={style.blackTriangle}>
                                <Tooltip
                                    text={t("This product is under extra pharmaco-vigilance monitoring", {
                                        ns: "common",
                                    })}
                                    placement="bottom"
                                >
                                    <Icon icon={mdiTriangle} color="black" />
                                </Tooltip>
                            </div>
                        )}
                    </div>
                }
                menu={
                    (medical && !isMinisite) || pageContext?.disableMegaMenu ? null : (
                        <JMCMegaMenu
                            siteMenu={isMinisite ? siteReference()?.site_menu : configuration?.site_menu}
                            interestingLinks={medical ? null : configuration?.interesting_links}
                            title={pageContext.miniSiteTitle}
                            maintenance={siteReference()?.maintenance_mode?.temporary_maintenance_mode ?? false}
                            isMinisite={isMinisite}
                            languageSelector={<LangSwitcher availableLocales={availableLocales} />}
                        />
                    )
                }
                languageSelector={<LangSwitcher availableLocales={availableLocales} />}
                userMenu={<AuthHeaderItem />}
                cart={cartMenuItem}
                navigate={jmcNavigate}
                index={process.env.GATSBY_SEARCH_REGION}
                suggestionsIndex={`${process.env.GATSBY_SEARCH_REGION.toLowerCase()}_sections_query_suggestions`}
                enableSearch={!!searchServiceEnabled && !isTCPPage && !isDedicatedSearchPage && !medical}
                HeaderWrapper={headerWrapper}
                useFullWidth={useFullWidth}
                medical={medical}
                enablePreviousSearches={isPreviousSearchEnabled()}
                enablePopularSearches={isPopularSearchEnabled()}
                enableAutocompleteSearch={isAutcompleteSearchEnabled()}
            />
        </>
    );
};

export default JMCHeader;
